import React from 'react';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { RegistrationForm } from './patterns/RegistrationForm/RegistrationForm';
import DecisionStep from './patterns/DecisionStep/DecisionStep';
import ZipAddressStepWithSearch from './patterns/ZipAddressStep/ZipAddressStepWithSearch';
import BuroValidationOTP from './patterns/BuroValidationOTP/BuroValidationOTP';
import FinancialGoals from './patterns/FinancialGoals/FinancialGoals';
import ContactDataRedirection from './patterns/ContactDataStep/ContactDataRedirection';
import CurpFeedbackRedirection from './patterns/PersonalInfoStep/FeedbackExperiment/CurpFeedbackRedirection';

const FINANCIAL_GOALS_EXPERIMENT_ID = '344539';
const FINANCIAL_GOALS_EXPERIMENT_VARIANT = '46713';

const RegistrationFormWithCURP = () => {
  const financialGoalsVariant = useFigPiiExperiment(FINANCIAL_GOALS_EXPERIMENT_ID);

  // Financial Goals Experiment
  if (financialGoalsVariant === FINANCIAL_GOALS_EXPERIMENT_VARIANT) {
    return (
      <RegistrationForm>
        <ContactDataRedirection />
        <CurpFeedbackRedirection />
        <ZipAddressStepWithSearch shouldTriggerOTP />
        <BuroValidationOTP />
        <FinancialGoals />
        <DecisionStep activeFinancialGoals />
      </RegistrationForm>
    );
  }

  // Original
  return (
    <RegistrationForm>
      <ContactDataRedirection />
      <CurpFeedbackRedirection />
      <ZipAddressStepWithSearch shouldTriggerOTP />
      <BuroValidationOTP />
      <DecisionStep />
    </RegistrationForm>
  );
};

export default RegistrationFormWithCURP;
